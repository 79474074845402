body { background-color: #ffffff; }
body { color: var(--clr-9760); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.1;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
@media #{$large-up} {
font-size: 50px;

}
}
h2 {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.1;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
font-family: 'Montserrat';
font-weight: 300;
font-style: normal;
line-height: 1.5;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 2;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
@media #{$large-up} {
font-size: 22px;

}
}
summary {
font-family: 'Macondo';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1;
font-size: 20px;

}
body {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
p { padding-bottom: 1em; }
a {color: var(--clr-9761);}
a:hover {color: var(--clr-9762);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-9761);
color: #ffffff;
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-9761);
color: #ffffff;
 }
.MES3 {
background-color: var(--clr-9761);
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
cite.MEC3{
color: #ffffff;
}
/* Secondary:4 */
.MES4 {
background-color: var(--clr-9762);
color: #ffffff;
 }
/* Secondary:5 */
.MES5 {
background-color: var(--clr-9762);
color: #ffffff;
 }
.MES5 {
background-color: var(--clr-9762);
color: #ffffff;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: #ffffff;
 }
 }
a.MEC5 { color: #ffffff;
&:hover { color: var(--clr-9761);}
 }
cite.MEC5{
color: #ffffff;
}
/* Dark:6 */
.MES6 {
background-color: var(--clr-9760);
color: #ffffff;
 }
/* Dark:7 */
.MES7 {
background-color: var(--clr-9760);
color: #ffffff;
 }
.MES7 {
background-color: var(--clr-9760);
color: #ffffff;
h1.MEC7, h2.MEC7, h3.MEC7, h4.MEC7, h5.MEC7, h6.MEC7 { color: #ffffff;
 }
 }
cite.MEC7{
color: #ffffff;
}
/* Main Menu:8 */
nav.me-Menu.MES8 {
& .menu-item.MEC8, & .menu-item.MEC8 > div.MEC8{ & > a.MEC8{color: var(--clr-9760);
text-transform: uppercase;
}
 &:hover > a.MEC8{color: var(--clr-9761);
}
 }
&.horizontal > .menu-item.MEC8 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC8 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC8 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC8 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 20px 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Home Slider Mask:9 */
.MES9 {
background-color: var(--clr-9763);
color: #ffffff;
padding: 15px;

@media #{$medium-up} {
padding: 15px 30px;

}
@media #{$large-up} {
padding: 15px 60px;

}
 }
.MES9 {
background-color: var(--clr-9763);
color: #ffffff;
padding: 15px;

@media #{$medium-up} {
padding: 15px 30px;

}
@media #{$large-up} {
padding: 15px 60px;

}
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: #ffffff;
 }
&:hover { h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: #ffffff;} }
h1.MEC9 { @media #{$medium-up} { font-size: 44px; }; }
h1.MEC9 { @media #{$large-up} { font-size: 75px; }; }
h2.MEC9 { @media #{$medium-up} { font-size: 38.5px; }; }
h2.MEC9 { @media #{$large-up} { font-size: 52.5px; }; }
h3.MEC9 { @media #{$medium-up} { font-size: 33px; }; }
h3.MEC9 { @media #{$large-up} { font-size: 45px; }; }
h4.MEC9 { @media #{$medium-up} { font-size: 27.5px; }; }
h4.MEC9 { @media #{$large-up} { font-size: 37.5px; }; }
h5.MEC9 { @media #{$medium-up} { font-size: 22px; }; }
h5.MEC9 { @media #{$large-up} { font-size: 30px; }; }
h6.MEC9 { @media #{$medium-up} { font-size: 19.8px; }; }
h6.MEC9 { @media #{$large-up} { font-size: 27px; }; }
 }
cite.MEC9{
color: #ffffff;
}
/* Home Slider:10 */
.MES10 {
& .slider-arrow {color: #ffffff;
font-size: 50px;
@media #{$medium-up} {
font-size: 80px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slider-arrow:hover{color: var(--clr-9761);
} }
/* Home Text Panel:11 */
.MES11 {
padding: 15px;

@media #{$medium-up} {
padding: 15px 20px;

}
@media #{$large-up} {
padding: 40px;

}
border-width: 5px 0 0 0;
border-style: solid;
border-color: var(--clr-9761) transparent transparent transparent;
 }
.MES11 {
padding: 15px;

@media #{$medium-up} {
padding: 15px 20px;

}
@media #{$large-up} {
padding: 40px;

}
border-width: 5px 0 0 0;
border-style: solid;
border-color: var(--clr-9761) transparent transparent transparent;
 }
/* Purple 40%:12 */
.MES12 {
background-color: var(--clr-9764);
color: #ffffff;
padding: 15px;

@media #{$medium-up} {
padding: 20px;

}
 }
.MES12 {
background-color: var(--clr-9764);
color: #ffffff;
padding: 15px;

@media #{$medium-up} {
padding: 20px;

}
h1.MEC12, h2.MEC12, h3.MEC12, h4.MEC12, h5.MEC12, h6.MEC12 { color: #ffffff;
 }
 }
cite.MEC12{
color: #ffffff;
}
/* Purple 60%:13 */
.MES13 {
background-color: var(--clr-9765);
color: #ffffff;
padding: 15px;

@media #{$medium-up} {
padding: 20px;

}
 }
.MES13 {
background-color: var(--clr-9765);
color: #ffffff;
padding: 15px;

@media #{$medium-up} {
padding: 20px;

}
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: #ffffff;
 }
 }
cite.MEC13{
color: #ffffff;
}
/* Purple 80%:14 */
.MES14 {
background-color: var(--clr-9766);
color: #ffffff;
padding: 15px;

@media #{$medium-up} {
padding: 20px;

}
 }
.MES14 {
background-color: var(--clr-9766);
color: #ffffff;
padding: 15px;

@media #{$medium-up} {
padding: 20px;

}
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: #ffffff;
 }
 }
cite.MEC14{
color: #ffffff;
}
/* Purple 100%:15 */
.MES15 {
background-color: var(--clr-9761);
color: #ffffff;
padding: 15px;

@media #{$medium-up} {
padding: 20px;

}
 }
.MES15 {
background-color: var(--clr-9761);
color: #ffffff;
padding: 15px;

@media #{$medium-up} {
padding: 20px;

}
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: #ffffff;
 }
 }
cite.MEC15{
color: #ffffff;
}
/* Credit:16 */
.MES16 {
background-color: var(--clr-9760);
color: #ffffff;
font-size: 12.6px;
@media #{$medium-up} {
font-size: 12.8px;
};
@media #{$large-up} {
font-size: 11.2px;
};
padding: 0 15px 10px 15px;

 }
.MES16 {
background-color: var(--clr-9760);
color: #ffffff;
font-size: 12.6px;
@media #{$medium-up} {
font-size: 12.8px;
};
@media #{$large-up} {
font-size: 11.2px;
};
padding: 0 15px 10px 15px;

h1.MEC16, h2.MEC16, h3.MEC16, h4.MEC16, h5.MEC16, h6.MEC16 { color: #ffffff;
 }
 }
cite.MEC16{
color: #ffffff;
font-size: 12.6px;
@media #{$medium-up} {
font-size: 12.8px;
};
@media #{$large-up} {
font-size: 11.2px;
};
}
/* Foot Menu:17 */
nav.me-Menu.MES17 {
& .menu-item.MEC17, & .menu-item.MEC17 > div.MEC17{ & > a.MEC17{color: #ffffff;
text-transform: uppercase;
}
 &:hover > a.MEC17{color: var(--clr-9761);
}
 }
&.horizontal > .menu-item.MEC17 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC17 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC17 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC17 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 0;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Email Content:23 */
.MES23 {
background-color: #ffffff;
color: var(--clr-9760);
padding: 5px;

 }
.MES23 {
background-color: #ffffff;
color: var(--clr-9760);
padding: 5px;

h1.MEC23, h2.MEC23, h3.MEC23, h4.MEC23, h5.MEC23, h6.MEC23 { color: var(--clr-9760);
 }
 }
cite.MEC23{
color: var(--clr-9760);
}
/* Responsive Menu:19 */
nav.responsive-menu {
.menu-item.MEC19{background-color: var(--clr-9761);}
& .menu-item.MEC19, & .menu-item.MEC19 > div.menu-item-wrap{ & > a.MEC19, & > i{color: #ffffff;
text-transform: uppercase;
text-align: center;
}
  }
& .menu-item.MEC19 { border:0;
border-color: #ffffff;border-style: dotted;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 10px;}

& .sub-menu{ .sub-menu {}}

 }
/* Purple Hollow:21 */
.MES21 {
background-color: transparent;
&:hover {background-color: transparent;}
color: var(--clr-9761);
&:hover { color: var(--clr-9766);}
padding: 10px 30px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-9761);
&:hover { border-color: var(--clr-9766); }
 }
/* Purple line:22 */
.MES22 {
padding: 30px 30px 30px 0;

& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-9761) transparent transparent transparent;}
& > hr {border-top-style: dotted;}
 }
/* Page Accordion:24 */
details.MES24 {
 }
